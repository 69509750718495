<template>
  <div class="d-flex flex-wrap align-items-center justify-content-between">
    <b-card class="flex-auto" v-if="showNow">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3>
            {{ itemsLength }}
          </h3>
          <p>
            {{ appCountText }}
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <feather-icon class="text-white bg-light-info rounded-circle" style="padding: 15px" icon="MenuIcon" size="60" />
        </div>
      </div>
    </b-card>
    <b-card v-else class="flex-auto">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3>
            <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
          </h3>
          <p>
            <b-skeleton width="80px" height="20px"></b-skeleton>
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <b-skeleton width="60px" height="60px"></b-skeleton>
        </div>
      </div>
    </b-card>

    <b-card class="flex-auto" v-if="showNow1">
      <div class="d-flex  align-items-center justify-content-between">
        <div>
          <h3>
            {{ totalInstall }}
          </h3>
          <p>
            {{ downloadText }}
          </p>
        </div>
        <div class="d-flex   align-items-center justify-content-center">
          <feather-icon icon="DownloadIcon" class="text-white bg-light-success rounded-circle" style="padding: 15px"
            size="60" />
        </div>
      </div>
    </b-card>
    <b-card v-else class="flex-auto">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3>
            <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
          </h3>
          <p>
            <b-skeleton width="80px" height="20px"></b-skeleton>
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <b-skeleton width="60px" height="60px"></b-skeleton>
        </div>
      </div>
    </b-card>

    <b-card class="flex-auto" v-if="showNow2">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3>
            {{ totalSuggests }}
          </h3>
          <p>
            {{ keywordSuggestsText }}
          </p>
        </div>
        <div class="d-flex  align-items-center justify-content-center">
          <feather-icon :icon="dIcon" class="text-white bg-light-warning rounded-circle" style="padding: 15px"
            size="60" />
        </div>
      </div>
    </b-card>
    <b-card v-else class="flex-auto">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3>
            <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
          </h3>
          <p>
            <b-skeleton width="80px" height="20px"></b-skeleton>
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <b-skeleton width="60px" height="60px"></b-skeleton>
        </div>
      </div>
    </b-card>

    <difficulty-score class="flex-auto" :data="data" :ready="ready" @ready="onReadyEvent" />

    <b-card v-if="!showNow3" class="flex-auto">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h3>
            <b-skeleton width="100px" height="30px" class="mb-2"></b-skeleton>
          </h3>
          <p>
            <b-skeleton width="80px" height="20px"></b-skeleton>
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <b-skeleton width="60px" height="60px"></b-skeleton>
        </div>
      </div>
    </b-card>

  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BSkeleton } from "bootstrap-vue";
import DifficultyScore from "@/components/market-manager/DifficultyScore.vue";

export default {
  name: "AnalyticsCard",
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BSkeleton,
    "difficulty-score": DifficultyScore,
  },
  data() {
    return {
      ready: false,
      showNow: false,
      showNow1: false,
      showNow2: false,
      showNow3: false,
    }
  },
  props: {
    from: {
      type: String,
      required: true,
    },
    data: {
      type: String,
    },
    itemsLength: {
      type: String,
      required: true,
    },
    totalInstall: {
      type: String,
      required: true,
    },
    totalSuggests: {
      type: String,
      required: true,
    },
    dIcon: {
      type: String,
      default: "ActivityIcon",
    },
    appCountText: {
      type: String,
      default: "App count",
    },
    downloadText: {
      type: String,
      default: "Downloads",
    },
    keywordSuggestsText: {
      type: String,
      default: "Keyword Suggests",
    },
  },
  methods: {
    onReadyEvent(isReady) {
      if (this.from !== 'app') {
        this.ready = true;
      } else {
        this.ready = isReady;
      }
    },
  },
  watch: {
    ready: {
      handler(newValue, oldValue) {
        if (this.from !== 'app') {
          newValue = true;
        }
        this.showNow = newValue
        this.showNow1 = newValue
        this.showNow2 = newValue
        this.showNow3 = newValue
      },
      immediate: true,
    },
  },
};
</script>
<style>
.flex-auto {
  flex: auto;
  margin-right: 5px;
}
</style>